/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 19:10:16
 * @Module: 地址管理
 */
 <template>
  <div>
    <div class="title">
      <hdqSection title="地址管理" more-text="" />
      <div class="button">
        <el-button size="small" @click="()=>disabled=!disabled">修改</el-button>
      </div>
      <div class="content">
        <el-descriptions :column="1" :contentStyle="{height:'28px'}" :labelStyle="{width:'80px',textAlign:'right'}">
          <el-descriptions-item label="收件人姓名">
            <span v-if="disabled">{{data.name}}</span>
            <el-input v-else v-model="data.name" size="mini" clearable></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="手机号码">
            <span v-if="disabled">{{data.phone}}</span>
            <el-input v-else v-model="data.phone" size="mini" clearable></el-input>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="邮政编码">
            <span v-if="disabled">{{data.postalCode}}</span>
            <el-input v-else v-model="data.postalCode" size="mini"></el-input>
          </el-descriptions-item> -->
          <el-descriptions-item label="详细地址">
            <span v-if="disabled">{{data.address}}</span>
            <el-input type="textarea" v-else v-model="data.address" size="mini" clearable></el-input>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="save">
        <el-button type="primary" @click="putCompanyAddress" v-show="!disabled">保存</el-button>
      </div>
    </div>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {
  },
  data () {
    return {
      data: {
        name: '--',
        phone: '--',
        address: '--'
      },
      disabled: true
    };
  },
  computed: {
    ...mapState({
      companyUuid: state => state.user.user.companyUuid
    })
  },
  mounted () {
    this.getCompanyAddress()
  },
  methods: {
    async getCompanyAddress () {
      const { companyUuid } = this
      const { status, data } = await this.$api.getCompanyAddress({ companyUuid })
      if (status === 200) {
        this.data = data;
      }
    },
    async putCompanyAddress () {
      const { companyUuid, data } = this
      const { status, info } = await this.$api.putCompanyAddress({ companyUuid, ...data })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.disabled = true;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.title {
  position: relative;
  .button {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }
  .content {
    padding: 100px 0;
    width: 500px;
    margin: 0 auto;
  }
  .save {
    height: 100px;
    width: 500px;
    margin: 0 auto;

    &/deep/.el-button {
      width: 500px;
    }
  }
}
</style>